<template>
  <div class="oekostromButton">
    <button
      class="btn"
      :class="type"
      @click="$emit('click')"
    >
      <div class="label">
        <slot></slot>
      </div>
    </button>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'OekostromButton',
  emits: ['click'],
  props: {
    type: {
      type: String,
      required: true
    }
  },
})
</script>

<style lang="scss">
.oekostromButton {
  padding: 4px;

  .btn {
    width: 100%;
    display: inline-block;
    box-sizing: border-box;
    text-decoration: none !important;
    font-family: var(--primaryFont);
    font-weight: bold;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: -.01em;
    padding: calc(.75em - 1px) calc(1.25em - 1px);
    border-radius: .5em;
    transition:
      color ease 250ms,
      transform ease 250ms,
      background-color ease 250ms,
      border-color ease 250ms,
      box-shadow ease 250ms;
  }

  .btn:hover {
    transform: scale(1.025);
  }

  .btn:active {
    transform: translateY(.05rem);
  }

  //Primary 
  .btn.primary {
    color: var(--navy800);
    background: var(--primary400);
    border: 1px solid var(--primary500);
  }

  .btn.primary:hover {
    color: var(--white);
    background-color: var(--navy800);
    border: 1px solid var(--navy900);
    transform: scale(1.025);
  }

  //Primary disabled
  .btn.primary-disabled {
    color: var(--navy600);
    background: var(--primary100);
    border: 1px solid var(--primary200);
    transform: unset;
  }

  //Secondary
  .btn.secondary {
    color: var(--navy800);
    background: var(--white);
    border: 1px solid var(--navy200);
    box-shadow: 0 2px 4px -1px rgb(5 6 5 / 10%);
  }

  .btn.secondary:hover {
    color: var(--white);
    background-color: var(--navy800);
    border: 1px solid var(--navy900);
    box-shadow: 0 3px 6px -1px rgb(5 6 5 / 15%);
    transform: scale(1.025);
  }

  //Secondary disabled
  .btn.secondary-disabled {
    color: var(--navy600);
    background: var(--white);
    border: 1px solid var(--primary300);
    transform: unset;
  }

  //Tertiary
  .btn.tertiary {
    padding: .75em 0;
    background-color: transparent;
    border: 0;
    box-shadow: none;
    color: var(--navy800);
  }

  .btn.tertiary:hover {
    box-shadow: none;
    transform: none;
    color: var(--navy600);
  }

  //Tertiary disabled
  .btn.tertiary-disabled {
    padding: .75em 0;
    background-color: transparent;
    border: 0;
    box-shadow: none;
    transform: none;
    color: var(--navy300);
  }

  .btn.tertiary-disabled:hover {
    box-shadow: none;
    transform: none;
    color: var(--navy600);
  }

  //Link
  .btn.link {
    padding: .75em 0;
    background-color: transparent;
    border: 0;
    box-shadow: none;
    color: var(--navy800);
  }

  .btn.link:hover {
    box-shadow: none;
    transform: none;
    color: var(--navy600);
  }

  //Link disabled
  .btn.link-disabled {
    padding: .75em 0;
    background-color: transparent;
    border: 0;
    box-shadow: none;
    transform: none;
    color: var(--navy300);
  }

  .btn.link-disabled:hover {
    box-shadow: none;
    transform: none;
    color: var(--navy600);
  }
}
</style>
