<template>
  <button
    class="defaultButton"
    :class="type"
    @click="$emit('click')"
  >
    <div class="label">
      <slot></slot>
    </div>
  </button>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'DefaultButton',
  emits: ['click'],
  props: {
    type: {
      type: String,
      required: true
    }
  }
})
</script>

<style lang="scss">
.defaultButton {
  width: 100%;
  padding: 13px;
  text-align: center;
  background: transparent;
  border: 2px solid transparent;
  border-radius: 12px;

  .label {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: var(--white);

    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    ion-spinner {
      height: 22px;
    }
  }
  
  //Primary Default State
  &.primary {
    background: var(--primary500);
    border: 2px solid var(--primary500);
    color: var(--primary-CTA-label-color);

    //Primary Pressing State
    &:active {
      box-shadow: inset 999px 999px 4px rgba(31, 52, 72, 0.08);
    }
  }

  //Primary Disabled state
  &.primary-disabled {
    background: var(--primary200);
    color: var(--navy500);

    .label {
      color: var(--navy500);
    }
  }

  //Secondary Default State
  &.secondary {
    background: var(--white);
    border: 2px solid var(--primary500);
    color: var(--primary500);

    .label {
      color: var(--primary500);
    }

    //Secondary Pressing State
    &:active {
      box-shadow: inset 999px 999px 4px rgba(0, 0, 0, 0.08);
    }
  }

  //Secondary Disabled state
  &.secondary-disabled {
    background: var(--white);
    color: var(--navy400);

    .label {
      color: var(--navy400);
    }
  }

  //Tertiary Default state
  &.tertiary {
    background: transparent;

    .label {
      color: var(--navy800)
    }

    //Primary Pressing State
    &:active {
      box-shadow: inset 999px 999px 4px rgba(38, 49, 59, 0.08);
    }
  }

  //Tertiary Disabled state
  &.tertiary-disabled {
    background: transparent;

    .label {
      color: var(--navy500)
    }
  }

  //Link Default State
  &.link {
    background: transparent;

    .label {
      text-decoration: underline;
      color: var(--navy600);
    }

    &:active {
      color: var(--navy800);
    }
  }

  //Link Disabled State
  &.link-disabled {
    background: transparent;

    .label {
      text-decoration: underline;
      color: var(--navy500);
    }
  }
}
</style>
