import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "sheet-modal-content" }
const _hoisted_2 = { class: "sheet-modal-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.appPlatform === _ctx.AppPlatform.app || _ctx.appPlatform === _ctx.AppPlatform.mobileWeb)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["sheetModalMobile", [
      _ctx.transformParams.showTransition ? 'show-transition' : '',
      _ctx.isModalOpen ? 'active' : ''
    ]]),
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('closeClick')))
        }, [
          _createElementVNode("div", {
            id: "sheetModalWrapper",
            onClick: _cache[0] || (_cache[0] = ($event: any) => ($event.stopPropagation())),
            class: _normalizeClass(["sheet-modal-wrapper", [
        _ctx.transformParams.showTransition ? 'show-transition' : '',
        _ctx.isModalOpen ? 'active' : ''
      ]]),
            style: _normalizeStyle([
        _ctx.transformParams.translateY !== null ? 'transform: translateY(' + _ctx.transformParams.translateY + 'px)' : '',
        `padding-bottom: ${_ctx.safeArea.bottom}px`
      ])
          }, [
            _createElementVNode("div", _hoisted_1, [
              _cache[4] || (_cache[4] = _createElementVNode("div", { class: "swipeIndicator" }, null, -1)),
              _renderSlot(_ctx.$slots, "content", {}, undefined, true)
            ])
          ], 6)
        ], 2))
      : _createCommentVNode("", true),
    (_ctx.appPlatform === _ctx.AppPlatform.desktopWeb)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "sheetModalDesktop",
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('closeClick')))
        }, [
          _createElementVNode("div", {
            id: "sheetModalWrapper",
            class: "sheet-modal-wrapper",
            onClick: _cache[2] || (_cache[2] = ($event: any) => ($event.stopPropagation()))
          }, [
            _createElementVNode("div", _hoisted_2, [
              _renderSlot(_ctx.$slots, "content", {}, undefined, true)
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}