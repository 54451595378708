import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "appButton" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OekostromButton = _resolveComponent("OekostromButton")!
  const _component_DefaultButton = _resolveComponent("DefaultButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.appConfig.partnerId === 'oekostrom')
      ? (_openBlock(), _createBlock(_component_OekostromButton, {
          key: 0,
          type: _ctx.type,
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click')))
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "default")
          ]),
          _: 3
        }, 8, ["type"]))
      : (_openBlock(), _createBlock(_component_DefaultButton, {
          key: 1,
          type: _ctx.type,
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('click')))
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "default")
          ]),
          _: 3
        }, 8, ["type"]))
  ]))
}