<template>
  <div class="appButton">
    <OekostromButton
      v-if="appConfig.partnerId === 'oekostrom'"
      :type="type"
      @click="$emit('click')"
    >
      <slot></slot>
    </OekostromButton>
    <DefaultButton
      v-else
      :type="type"
      @click="$emit('click')"
    >
      <slot></slot>
    </DefaultButton>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
//Interface & Enums
import { StoreInterface } from '@/store';
//Components
import DefaultButton from '@/components/General/Buttons/DefaultButton.vue';
import OekostromButton from '@/components/General/Buttons/OekostromButton.vue';
//Other
import { segmentTrackEvent } from '@/services/analytics.service';
import * as _ from 'lodash'

export default defineComponent({
  name: 'AppButton',
  emits: ['click'],
  components: {
    DefaultButton,
    OekostromButton
  },
  props: {
    type: {
      type: String,
      required: true
    },
    flow: String,
    name: String,
    activity: String,
    disableAnalytics: Boolean
  },
  data() {
    return {
      debouncedClick: () => {}
    }
  },
  setup() {
    const store: StoreInterface = useStore()
    const route = useRoute()

    return {
      route,
      appConfig: computed(() => store.state.appConfig)
    }
  },
  created() {
    this.debouncedClick = _.debounce(this.handleClick, 500, { leading: true, trailing: false });
  },
  methods: {
    handleClick() {
      this.handleAnalytics();
      this.$emit('click');
    },

    handleAnalytics() {
      if (this.disableAnalytics) return;
      segmentTrackEvent('Button Pressed', {
        path: this.route.path,
        flow: this.flow,
        name: this.name,
        activity: this.activity
      });
    }
  }
})
</script>

<style lang="scss">
.appButton {
  width: 100%;
  margin: 6px 0px;
}
</style>
