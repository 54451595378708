import store, { AppConfigInterface, AppPlatform, StateMutations, StoreInterface } from '@/store'
import { isPlatform, modalController } from '@ionic/vue';
import { getCache, getEncryptedCache, removeCache, setCache } from '@/store/storage/ionicStorage';
//Interfaces & Enums
import { UserStateMutations } from '@/store/modules/user';
import { AssetInterface } from '@/types/v2/asset/interfaces';
import { AssetStateMutations } from '@/types/v2/asset/enums';
import { UserActivationTermsAndConditionsInterface } from '@/types/interfaces';
import { TermsTypes } from '@/types/enums';
//Endpoints
import { getAppConfig } from '@/api/middlewareApi';
//Components
import InfoModal from '@/components/Asset/Desktop/Modals/InfoModal.vue'
//Helpers
import * as _ from 'lodash'
import { resolveAppConfigColors, resolveAppConfigFonts, resolvePartnerStyles } from '@/helpers/GlobalFunctions'

declare const google: any;

export const clamp = (x: number, min: number, max: number): number => {
  return x < min ? min : x > max ? max : x;
};

export const sortValues = (key: any, order = 'asc') => {
  return function innerSort(a: any, b: any) {
    // @ts-ignore
    const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key];
    // @ts-ignore
    const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key];

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return order === 'desc' ? comparison * -1 : comparison;
  };
};

export function addHoursToDate(numOfHours: number, date: Date) {
  date.setTime(date.getTime() + numOfHours * 60 * 60 * 1000);
  return date;
}

export function addMinutesToDate(numOfMinutes: number, date: Date) {
  date.setTime(date.getTime() + numOfMinutes * 60 * 1000);
  return date;
}

/*
 * General utils for managing cookies in Typescript.
 * Copied from https://gist.github.com/joduplessis/7b3b4340353760e945f972a69e855d11
 */
export function setCookie(name: string, val: string) {
  const date = new Date();
  const value = val;

  // Set it expire in 90 days
  date.setTime(date.getTime() + 90 * 24 * 60 * 60 * 1000);

  // Set it
  document.cookie =
    name + '=' + value + '; expires=' + date.toUTCString() + '; path=/';
}

export function calcTimeDifference(date: Date): string {
  // time diff
  const diff = new Date().getTime() - date.getTime();

  // get weeks
  const weeks = Math.floor(diff / (7 * 24 * 60 * 60 * 1000));

  // get days
  const days = Math.floor(diff / (1000 * 60 * 60 * 24));
  // get hours
  const hours = Math.floor(diff / (1000 * 60 * 60)) % 24;
  // get minutes
  const minutes = Math.floor(diff / (1000 * 60)) % 60;
  // get seconds
  const seconds = Math.floor(diff / 1000) % 60;

  if (weeks > 0) {
    return weeks + 'w';
  }
  if (days > 0) {
    return days + 'd';
  }
  if (hours > 0) {
    return hours + 'h';
  }
  if (minutes > 0) {
    return minutes + 'm';
  }
  if (seconds > 0) {
    return 'jetzt';
  }
  return 'jetzt';
}

export async function doItLater(delay: number, callback: () => void) {
  return await new Promise<void>(resolve => {
    setTimeout(() => {
      callback();
      resolve();
    }, delay);
  });
}

export async function hideScrollbars() {
  let ionContentList = [] as any
  ionContentList = document.querySelectorAll("ion-content:not(.scrollbarHidden)")
  if(ionContentList.length > 0) {
    for(let i=0; i < ionContentList.length; i++) {
      const styles = document.createElement('style');
      styles.textContent = `
        ::-webkit-scrollbar {
          width: 0px !important;
          display: none !important;
          background: transparent !important;
          -webkit-appearance: none !important;
        }

        /* Track */
        ::-webkit-scrollbar-track {
          width: 0px !important;
          display: none !important;
          background: transparent !important;
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
          width: 0px !important;
          display: none !important;
          background: transparent !important;
        }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
          width: 0px !important;
          display: none !important;
          background: transparent !important;
        }
      `;

      ionContentList[i].shadowRoot.appendChild(styles)
      ionContentList[i].classList.add('scrollbarHidden')
    }
  }
}


export async function checkForCachedUser() {
  const storedUser = await store.getters.getUser
  if(storedUser.userId === '') {
    const encryptedUser = await getEncryptedCache('cachedUser')
    if(encryptedUser !== '') {
      const userData = JSON.parse(encryptedUser)

      //Clear cached user if it contains old structure
      if(!userData.data) return await removeCache('cachedUser')

      const encryptedAssets = await getCache('cachedAssets')
      const assetsData = JSON.parse(encryptedAssets) as Array<AssetInterface>
      //Store user
      await store.commit(UserStateMutations.setUserWithObject, userData.data)
      await store.commit(UserStateMutations.setProcessedUserData, userData.processedData)
      await store.commit(StateMutations.setLoggedIn, true)
      //Store assets
      await store.commit(AssetStateMutations.storeAllAssets, assetsData);
      await store.commit(AssetStateMutations.updateAssetIsFetched);
    }
  }
}

export async function openDesktopInfoModal(info: any) {
  const modal = await modalController.create({
    component: InfoModal,
    cssClass: 'infoModalDesktop',
    componentProps: {
      info: info,
      closeClick: () => modalController.dismiss()
    },
    canDismiss: true,
  });
  return modal.present();
}

export function prepareBodyComplianceInformationTerms(appConfig: AppConfigInterface) {
  let body = {} as UserActivationTermsAndConditionsInterface

  for(let i=0;i<appConfig.userActivationModules.termsAndConditions.length;i++) {
    switch(appConfig.userActivationModules.termsAndConditions[i]) {
      case TermsTypes.brickwise:
        body['brickwiseTermsTimestamp'] = new Date() as unknown as string
        break;
      case TermsTypes.brickwise_customer:
        body['brickwiseCustomerInformationTimestamp'] = new Date() as unknown as string
        break;
      case TermsTypes.fourthline:
        body['fourthlineTermsTimestamp'] = new Date() as unknown as string
        break;
      case TermsTypes.effecta:
        body['effectaTermsTimestamp'] = new Date() as unknown as string
        break;
      case TermsTypes.mangopay:
        body['mangopayTermsTimestamp'] = new Date() as unknown as string
        break;
      case TermsTypes.ip:
        body['termsIpAddress'] = new Date() as unknown as string
        break;
      case TermsTypes.realpart:
        body['realpartTermsTimestamp'] = new Date() as unknown as string
        break;
      case TermsTypes.realpart_privacy:
        body['realpartPrivacyTermsTimestamp'] = new Date() as unknown as string
        break;
    }
  }

  return body
}

export async function checkForPlatformAndConfig() {
  const appStore = store as StoreInterface

  if(!appStore.state.appPlatform) {
    const platform = isPlatform('capacitor') ? AppPlatform.app : AppPlatform.desktopWeb
    await store.commit(StateMutations.setAppPlatform, platform)
  }

  if(_.isEmpty(appStore.state.appConfig)) {
    await handleAppConfig()
  }
}

export async function handleAppConfig() {
  const appStore = store as StoreInterface

  try {
    // We use the ionic storage to cache the app config
    const cachedAppConfig = await getCache('cachedAppConfig')
    const setAppConfig = async (appConfig: AppConfigInterface) => {
      await store.commit(StateMutations.setAppConfig, appConfig)
    
      //If there is not intro slides in the app, this must be updated cuz of future redirects; no intro slides on desktop
      if(!appConfig.featureFlags.introSlidesSignUp || appConfig.introSlides.length == 0 || appStore.state.appPlatform === AppPlatform.desktopWeb) {
        store.commit(StateMutations.setIntroSlidesWatched, true)
      }
      resolveAppConfigColors(appConfig.colors)
      resolveAppConfigFonts(appConfig.font)
      resolvePartnerStyles(appConfig.partnerId)
    }
    const fetchFromApi = async ()  => {
      const appConfig = await getAppConfig()
      if(appConfig && cachedAppConfig != JSON.stringify(appConfig)) { // If appConfig is changed, update it in the background
        setAppConfig(appConfig)
        setCache('cachedAppConfig', JSON.stringify(appConfig))
      }
    }
    if (cachedAppConfig) {
      const parsedConfig = JSON.parse(cachedAppConfig) as AppConfigInterface
      setAppConfig(parsedConfig)
      fetchFromApi()
    } else {
      await fetchFromApi()
    }
  } catch(err) {
    console.log(err)
  }
}
