<template>
  <ion-item
    :mode="'md'"
    class="inputItem"
    :style="[backgroundStyle, isReadOnly ? 'pointer-events: none' : '']"
  >
    <ion-input
      :mode="'md'"
      :ref="ref"
      :type="type"
      :value="modelValue"
      :readonly="isReadOnly"
      :label="label"
      :labelPlacement="labelPlacement"
      :placeholder="placeholder"
      :autocomplete="autocomplete"
      :autocapitalize="autocapitalize"
      :inputmode="inputmode"
      @input="$emit('update:modelValue', $event.target.value)"
    >
    </ion-input>
    <div class="input-append">
      <slot name="append"></slot>
    </div>
  </ion-item>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { IonItem, IonInput } from '@ionic/vue';
import { TextFieldTypes, AutocompleteTypes } from '@ionic/core';

export default defineComponent({
  name: 'AppInput',
  emits: ['update:modelValue'],
  components: {
    IonItem,
    IonInput
  },
  props: {
    ref: { type: String, required: false },
    type: { type: String as PropType<TextFieldTypes>, required: false, default() { return 'text' } },
    modelValue: { type: [Number, String], required: false },
    isReadOnly: { type: Boolean, required: false },
    label: { type: String, required: false },
    labelPlacement: { type: String, required: false, default() { return 'floating' } },
    placeholder: { type: String, required: false },
    autocomplete: { type: String as PropType<AutocompleteTypes>, required: false, default() { return 'off' } },
    autocapitalize: { type: String, required: false, default() { return 'off' } },
    inputmode: { type: String, required: false },
    backgroundColor: { type: String, required: false, default() { return 'var(--navy200)' } },
  },
  computed: {
    backgroundStyle() {
      return {
        '--ion-item-background': `${this.backgroundColor}`
      }
    }
  }
})
</script>

<style lang="scss">
ion-item {
  &.inputItem {
    --border-color: transparent;
    --min-height: 65px;
    --border-radius: var(--borderRadius);

    ion-input {
      height: 100%;
      --placeholder-color: var(--navy600) !important;
      --placeholder-opacity: 1;

      label {
        .label-text-wrapper {
          color: var(--navy600) !important;
        }

        .native-wrapper {
          input {
            caret-color: var(--navy800) !important;
            color: var(--navy800);

            &:-webkit-autofill,
            &:-webkit-autofill:hover, 
            &:-webkit-autofill:focus, 
            &:-webkit-autofill:active {
                -webkit-background-clip: text;
                -webkit-text-fill-color: var(--navy800);
            }
          }
        }
      }
    }

    .input-append {
      position: absolute;
      right: 12px;
      z-index: 999;
      height: 100%;
      display: flex;
      align-items: center;
    }
  }
}
</style>
