//Endpoints
import { getUserData } from '@/api/middlewareApi';
//Interfaces & Enums
import { TransactionTaxesInterface, TransactionInfoInterface, ExperienceEnum, ExperienceTransactionAmountEnum, ExperienceTransactionValueEnum } from '@/types/interfaces';
import { AssetOfferInterface } from '@/types/v2/asset/interfaces';
import { TAssetStage } from '@brickwise/asset-validation';
import { AssetOfferStatus, AssetOfferType } from '@/types/v2/asset/enums';
//Other
import * as _ from 'lodash';
import { setCookie } from '@/helpers/HelperFunctions';
import { setEncryptedCache } from '../storage/ionicStorage';

export enum UserStateMutations {
  setUserWithObject = 'setUserWithObject',
  setProcessedUserData = 'setProcessedUserData',
  setEmptyUser = 'setEmptyUser',
  updateUserLikes = 'updateUserLikes'
}

export enum UserStateActions {
  loadUserData = 'loadUserData'
}

export enum UserStatus {
  new = 'new',
  verified = 'verified',
  pending = 'pending',
  active = 'active',
  deactivated = 'deactivated'
}

export enum UserType {
  user = 'user',
  privateUser = 'privateUser'
}

export interface UserLikes {
  _id: string;
  assetId: string;
}

export enum SecurityAppStatus {
  new = 'new',
  disconnected = 'disconnected',
  active = 'active'
}

export enum KnowledgeAndExperienceUserStatus {
  NotProvided = 'NotProvided',
  NoInformation = 'NoInformation',
  Appropriate = 'Appropriate',
  NotAppropriate = 'NotAppropriate'
}

export interface UserAsset {
  id: string;
  shares: number;
  stage: TAssetStage;
  transactionAmount: number;
  averageSharePrice: number;
}

export interface TaxIdInterface {
  country: string;
  taxId: string;
}

export interface ProcessedUserAsset extends UserAsset {
  collectedRent: number;
  availableShares: number;
}

export enum TransactionMethod {
  account = 'account',
  transfer = 'transfer',
  creditCard = 'creditCard',
  debitCard = 'debitCard',
  bankWire = 'bankWire'
}

export enum TransactionStatus {
  init = 'init',
  pending = 'pending',
  charged = 'charged',
  reserved = 'reserved',
  success = 'success',
  error = 'error',
  canceled = 'canceled',
  canceledOffer = 'canceledOffer',
  refund = 'refund'
}

export enum TransactionType {
  buy = 'buy',
  sell = 'sell',
  charge = 'charge',
  payout = 'payout',
  rent = 'rent',
  bonus = 'bonus'
}

export interface UserTransaction {
  amount: number;
  assetId: string;
  bonus?: {
    amount: number;
    title: string;
  };
  createdAt: Date;
  fee: {
    percentage: number;
    amount: number;
  };
  id: string;
  method: TransactionMethod;
  paymentInformation?: string;
  paymentProviderInformation: {
    transactionId: string;
    transactionResultMessage: string;
  };
  receiver: string;
  sender: string;
  sharePrice: number;
  shares: number;
  status: TransactionStatus;
  taxes: Array<TransactionTaxesInterface>;
  title: string;
  transactionInformation: TransactionInfoInterface;
  tradeInformation: {
    ISIN: string;
    investmentDocument: string;
    tradingPlace: string;
    tradingType: string;
  };
  type: TransactionType;
  updatedAt: Date;
}
export interface UserState {
  lastUpdate: number;
  data: User;
  processedData: ProcessedUserData;
}

export interface User {
  userId: string;
  email: string;
  phone: string;
  status: UserStatus;
  userType: UserType;
  firstName: string;
  lastName: string;
  marketingNewsletter: boolean;
  createdAt: Date;
  updatedAt: Date;
  likes: Array<UserLikes>;
  wallet: {
    address: string;
    publicKey: string;
  };
  securityApp: {
    status: SecurityAppStatus;
    timestamp: Date;
  };
  account: {
    amount: number;
    IBAN: string;
    BIC: string;
    churchTax: number|null;
    taxIds: Array<TaxIdInterface>;
  };
  payoutAccount: {
    IBAN: string;
    owner: string;
  };
  assets: Array<UserAsset>;
  offers: Array<AssetOfferInterface>;
  transactions: Array<UserTransaction>;
  finance: {
    closedAmount: number;
    closedProfit: number;
  };
  personalInformation: {
    domicile: string;
    PLZ: string;
    birthCity: string;
    birthCountry: string;
    birthDate: string;
    city: string;
    nationality: string;
    street: string;
    socialSecurityNumber: string;
  };
  registrationProcessStatus: {
    personalInformation: boolean;
    paymentProviderCreation: boolean;
    kycDocuments: string;
    knowledgeAndExperience: boolean;
    securityApp: boolean;
    accountPayIn: boolean;
    userWasActivatedOnce: boolean;
    idNow?: {
      id: string;
      status: string;
      estimatedWaitingTimeOnBegin: number;
      startTime: Date;
      endTime: Date;
      duration: number;
    };
  };
  complianceInformation: {
    knowledgeAndExperience: KnowledgeAndExperienceInterface;
    knowledgeAndExperienceStatus: KnowledgeAndExperienceUserStatus;
    knowledgeAndExperienceBoundary: number;
    identificationDocument: {
      type: string;
      personalId: string;
      number: string;
      validFrom: string;
      validUntil: string;
    };
    terms: any;
  };
  marketingData: MarketingDataInterface;
  platformProvider?: string;
  featureTesting: boolean;
}
export interface KnowledgeAndExperienceInterface {
  kgParticipationAsBuildersModel?: { knowledge: boolean; experience: boolean; };
  stocks?: { knowledge: boolean; experience: boolean; };
  bonds?: { knowledge: boolean; experience: boolean; };
  certificates?: { knowledge: boolean; experience: boolean; };
  savingsAccount?: { knowledge: boolean; experience: boolean; };
  optionsAndFutures?: { knowledge: boolean; experience: boolean; };
  riskAppetite?: string;
  investmentHorizon?: string;
  availableAmount?: number;
  investmentFund?: { knowledge: boolean; experience: ExperienceEnum; };
  governmentBonds?: { knowledge: boolean; experience: ExperienceEnum; };
  certificate?: { knowledge: boolean; experience: ExperienceEnum; };
  participationPaper?: { knowledge: boolean; experience: ExperienceEnum; };
  share?: { knowledge: boolean; experience: ExperienceEnum; };
  virtualShare?: { knowledge: boolean; experience: ExperienceEnum; };
  independentService?: ExperienceEnum;
  investmentConsultancyService?: ExperienceEnum;
  investmentManagementService?: ExperienceEnum;
  averageYearlyTransactions?: ExperienceTransactionAmountEnum;
  averageTransactionValue?: ExperienceTransactionValueEnum;
}

export interface MarketingDataInterface {
  financialAdvisor: string;
  affiliatedBy: string;
}

export interface ProcessedUserData {
  collectedRent: number;
  totalTransactionAmount: number;
  account: {
    amount: number;
    availableAmount: number;
  };
  transactions: Array<UserTransaction>;
  assets: Array<ProcessedUserAsset>;
}

const emptyUser = {
  userId: '',
  email: '',
  phone: '',
  status: UserStatus.new,
  userType: UserType.user,
  firstName: '',
  lastName: '',
  marketingNewsletter: false,
  createdAt: new Date(),
  updatedAt: new Date(),
  likes: [],
  wallet: {
    address: '',
    publicKey: ''
  },
  securityApp: {
    status: SecurityAppStatus.new,
    timestamp: new Date()
  },
  account: {
    amount: 0,
    IBAN: '',
    BIC: '',
    taxIds: [],
    churchTax: null
  },
  payoutAccount: {
    IBAN: '',
    owner: ''
  },
  assets: [],
  offers: [],
  transactions: [],
  finance: {
    closedAmount: 0,
    closedProfit: 0
  },
  personalInformation: {
    domicile: '',
    PLZ: '',
    birthCity: '',
    birthCountry: '',
    birthDate: '',
    city: '',
    nationality: '',
    street: '',
    socialSecurityNumber: ''
  },
  complianceInformation: {
    knowledgeAndExperience: {} as KnowledgeAndExperienceInterface,
    knowledgeAndExperienceStatus:
      KnowledgeAndExperienceUserStatus.NoInformation,
    knowledgeAndExperienceBoundary: 0,
    identificationDocument: {
      type: '',
      personalId: '',
      number: '',
      validFrom: '',
      validUntil: ''
    },
    terms: null
  },
  marketingData: {} as MarketingDataInterface,
  registrationProcessStatus: {
    personalInformation: false,
    paymentProviderCreation: false,
    kycDocuments: '',
    knowledgeAndExperience: false,
    securityApp: false,
    accountPayIn: false,
    userWasActivatedOnce: false
  },
  platformProvider: '',
  featureTesting: false,
} as User;

const emptyProcessedData = {
  collectedRent: 0,
  totalTransactionAmount: 0,
  account: {
    amount: 0,
    availableAmount: 0
  },
  assets: [],
  transactions: []
};

export const userModule = {
  state: (): UserState => ({
    lastUpdate: Date.now(),
    data: _.cloneDeep(emptyUser),
    processedData: _.cloneDeep(emptyProcessedData)
  }),
  mutations: {
    setUserWithObject(state: UserState, user: User) {
      Object.keys(state.data).forEach((key: any) => {
        // @ts-ignore
        if (user[key] || user[key] === false) {
          // @ts-ignore
          state.data[key] = user[key];
        }
      });
      state.lastUpdate = Date.now();
      
    },
    setProcessedUserData(state: UserState, processedData: ProcessedUserData) {
      state.processedData = processedData;
      state.lastUpdate = Date.now();
    },
    setEmptyUser(state: UserState) {
      state.data = _.cloneDeep(emptyUser);
      state.processedData = _.cloneDeep(emptyProcessedData);
      state.lastUpdate = Date.now();
    },

    updateUserLikes(state: UserState, data: any) {
      if(data.like) {
        const likeObj = {
          _id: state.data.likes.length.toString(),
          assetId: data.assetId
        }
        state.data.likes.push(likeObj)
      } else {
        const assetIndex = state.data.likes.findIndex(item => item.assetId === data.assetId)
        state.data.likes.splice(assetIndex, 1)
      }
    }
  },
  getters: {
    getUser: (state: UserState) => {
      return state.data
    }
  },
  actions: {
    async loadUserData(context: any, externalUser?: any) {
      try {
        const user: { data: User } = externalUser ? {data: externalUser.data} : await getUserData();
        context.commit(UserStateMutations.setUserWithObject, user.data);
        // Process user data:
        // 1. calculate collected rent in total and on assets; also sum total transactionAmount
        const processedAssets: Array<ProcessedUserAsset> = [];
        let collectedRent = 0;
        let totalTransactionAmount = 0;
        // Filter deactivated Assets
        const filteredUserAssets = user.data.assets.filter(
          a =>
            a.stage === TAssetStage.primary || a.stage === TAssetStage.secondary
        );
        for (const asset of filteredUserAssets) {
          let collectedRentAsset = 0;
          // first add just collected rent from rental transactions
          user.data.transactions
            .filter(
              t => t.assetId === asset.id && t.type === TransactionType.rent
            )
            .forEach(t => (collectedRentAsset += t.amount));
          // second add also rentalBonus Transactions
          user.data.transactions
            .filter(t => t.paymentInformation === `rentalCredit_${asset.id}`)
            .forEach(t => (collectedRentAsset += t.amount));
          collectedRent += collectedRentAsset;
          totalTransactionAmount += asset.transactionAmount;
          // calculate available and not offered shares for asset
          const filteredOffers: Array<AssetOfferInterface> = user.data.offers
            .filter(o => o.assetId === asset.id)
            .filter(
              o =>
                o.status === AssetOfferStatus.new ||
                o.status === AssetOfferStatus.open ||
                o.status === AssetOfferStatus.accepted
            );
          let availableShares = asset.shares;
          for (const offer of filteredOffers) {
            if (
              offer.type === AssetOfferType.sell &&
              offer.firstParty === user.data.wallet.address
            )
              availableShares -= offer.amount;
            if (
              offer.type === AssetOfferType.buy &&
              offer.secondParty === user.data.wallet.address
            )
              availableShares -= offer.amount;
          }
          // Push the processed asset
          processedAssets.push({
            ...asset,
            collectedRent: collectedRentAsset,
            availableShares
          });
        }
        // 2. process user transactions and user available amount
        const processedTransactions: Array<UserTransaction> = [];
        let amount = user.data.account.amount;
        for (const t of user.data.transactions) {
          const transaction: UserTransaction = { ...t };
          // if the current user is the secondParty of a Buy-TradeTransaction the shown amount needs to be deducted
          if (
            transaction.type === TransactionType.buy &&
            transaction.sender !== user.data.userId &&
            transaction.receiver === user.data.userId
          ) {
            transaction.type = TransactionType.sell;
            transaction.amount =
              Math.round(
                (transaction.amount - 2 * transaction.fee.amount) * 100
              ) / 100;
          }
          // Sum up all pending transaction amounts
          if (
            transaction.type === TransactionType.buy &&
            (transaction.status === TransactionStatus.pending ||
              transaction.status === TransactionStatus.reserved)
          ) {
            amount += transaction.amount;
            // if there is an bonus in the reserved transactions it has to be substracted
            if (transaction.bonus && transaction.bonus.amount)
              amount -= transaction.bonus.amount;
          }
          // if the buy transaction has an involved bonus this amount has to be added
          if (
            transaction.type === TransactionType.buy &&
            transaction.bonus &&
            transaction.bonus.amount
          ) {
            transaction.amount =
              Math.round(
                (transaction.amount - transaction.bonus.amount) * 100
              ) / 100;
          }
          processedTransactions.push(transaction);
        }
        context.commit(UserStateMutations.setProcessedUserData, {
          collectedRent,
          totalTransactionAmount,
          account: { availableAmount: user.data.account.amount, amount },
          assets: processedAssets,
          transactions: processedTransactions
        });
        if (!externalUser) setEncryptedCache('cachedUser', JSON.stringify(context.state))
        setCookie('brickwiseUserId', user.data.userId);
      } catch (err) {
        console.log(err);
      }
    }
  }
};
