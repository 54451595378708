import store from '@/store'
//Endpoints
import { getMiddlewareAssets } from '@/api/middlewareApi';
import { getLaunchpadAssets } from "@/api/stoApi";
//Interfaces & Enums
import { AssetStateInterface, AssetInterface, AssetDiagramDataInterface, AssetRecordsDataInterface, AssetOfferInterface, AssetUpdateDataInterface, MiddlewareAssetInterface } from "@/types/v2/asset/interfaces"
import { AssetStateMutations } from "@/types/v2/asset/enums";
import { TCompleteAssetSchema } from '@brickwise/asset-validation/dist/schema/completeAsset';
import { TAssetStage } from '@brickwise/asset-validation';
//Other
import { getCache, setCache } from '../storage/ionicStorage';

export const assetsModule = {
  state: (): AssetStateInterface => ({
    assetsList: [] as Array<AssetInterface>,
    assetsFetched: false
  }),

  mutations: {
    storeAllAssets(state: AssetStateInterface, assets: Array<AssetInterface>) {
      state.assetsList = assets
    },

    updateAssetIsFetched(state: AssetStateInterface, value: boolean) {
      state.assetsFetched = true
    },

    loadAssetOffers(state: AssetStateInterface, data: {assetId: string; data: Array<AssetOfferInterface>}) {
      Object.assign(state.assetsList.find((item: AssetInterface) => item.data.id === data.assetId)!, {
        offers: data.data,
      })
    },

    loadDiagramData(state: AssetStateInterface, newData: any) {
      Object.assign(state.assetsList.find((item: AssetInterface) => item.data.id === newData.assetId)!, {
        diagramData: newData.data,
      })
    },

    loadAssetRecords(state: AssetStateInterface, data: any) {
      Object.assign(state.assetsList.find((item: AssetInterface) => item.data.id === data.assetId)!, {
        records: data.recordsData ? data.recordsData : [],
        recordsFetched: true
      })
    },

    likeAsset(state: AssetStateInterface, data: any) {
      const asset = state.assetsList.find((item: AssetInterface) => item.data.id === data.assetId)!
      Object.assign(state.assetsList.find((item: AssetInterface) => item.data.id === data.assetId)!, {
        likes: data.like ? asset.likes + 1 : asset.likes - 1
      })
    },

    updateUserInvested(state: AssetStateInterface, data: any) {
      Object.assign(state.assetsList.find((item: AssetInterface) => item.data.id === data.assetId)!, {
        userInvested: data.invested
      })
    },

    updateAsset(state: AssetStateInterface, data: AssetUpdateDataInterface) {
      Object.assign(state.assetsList.find((item: AssetInterface) => item.data.id === data.id)!, {
        data: data.launchpadAsset,
        availableBricksSecondary: data.middlewareAsset.availableBricksSecondary,
        lowestOfferPrice: data.middlewareAsset.lowestOfferPrice,
        forcedRanking: data.middlewareAsset.forcedRanking || 1000,
        middlewareDocuments: data.middlewareAsset.documents,
        green: data.middlewareAsset.green as boolean,
        onlyPrimary: data.middlewareAsset.onlyPrimary,
        likes: data.middlewareAsset.likes,
        fees: data.middlewareAsset.fees
      })
    },

    storeAsset(state: AssetStateInterface, data: AssetUpdateDataInterface) {
      const assetObject = {
        data: data.launchpadAsset as TCompleteAssetSchema,
        diagramData: {} as AssetDiagramDataInterface,
        records: [] as Array<AssetRecordsDataInterface>,
        offers: [] as Array<AssetOfferInterface>,
        fees: data.middlewareAsset.fees,
        lowestOfferPrice: data.middlewareAsset.lowestOfferPrice,
        likes: data.middlewareAsset.likes,
        green: data.middlewareAsset.green as boolean,
        forcedRanking: data.middlewareAsset.forcedRanking || 1000,
        middlewareDocuments: data.middlewareAsset.documents,
        onlyPrimary: data.middlewareAsset.onlyPrimary,
        userInvested: false,
        recordsFetched: false
      }

      state.assetsList.push(assetObject)
    }
  },
  actions: {
    async fetchAssets(context: any) {
      try {
        const assetObjects = [] as Array<AssetInterface>
        // We use the ionicStorage to cache the assets
        const cachedAssets = await getCache('cachedAssets');
        const fetchFromAPI = async () => {
          const appConfig = store.getters.getAppConfig
          const middlewareAssets = (await getMiddlewareAssets()).data

          const filteredMiddlewareAssets = middlewareAssets.filter((item: MiddlewareAssetInterface) => item.apps.includes(appConfig.partnerId))
          const filteredMiddlewareIds = filteredMiddlewareAssets.map((item: MiddlewareAssetInterface) => item.id)
          const launchpadAssets = (await getLaunchpadAssets(JSON.stringify(filteredMiddlewareIds))).data;

          for (let i=0; i<filteredMiddlewareAssets.length; i++) {
            const launchpadAsset = launchpadAssets.find((item: TCompleteAssetSchema) => item.id === filteredMiddlewareAssets[i].id)
            const assetObject = {
              data: launchpadAsset as TCompleteAssetSchema,
              diagramData: {} as AssetDiagramDataInterface,
              records: [] as Array<AssetRecordsDataInterface>,
              offers: [] as Array<AssetOfferInterface>,
              fees: filteredMiddlewareAssets[i].fees,
              availableBricksSecondary: filteredMiddlewareAssets[i].availableBricksSecondary,
              lowestOfferPrice: filteredMiddlewareAssets[i].lowestOfferPrice,
              likes: filteredMiddlewareAssets[i].likes,
              green: filteredMiddlewareAssets[i].green as boolean,
              forcedRanking: filteredMiddlewareAssets[i].forcedRanking || 1000,
              middlewareDocuments: filteredMiddlewareAssets[i].documents,
              onlyPrimary: filteredMiddlewareAssets[i].onlyPrimary,
              userInvested: false,
              recordsFetched: false
            }
            assetObjects.push(assetObject)
          }
          context.commit(AssetStateMutations.storeAllAssets, assetObjects)
          context.commit(AssetStateMutations.updateAssetIsFetched)
          setCache('cachedAssets', JSON.stringify(assetObjects))
        }
        if (cachedAssets) {
          const parsedAssets = JSON.parse(cachedAssets) as Array<AssetInterface>
          context.commit(AssetStateMutations.storeAllAssets, parsedAssets);
          context.commit(AssetStateMutations.updateAssetIsFetched);
          fetchFromAPI()
          return;
        } else {
          await fetchFromAPI()
        }
      } catch(err: any) {
        console.log(err)
      }
    },
  },

  getters: {
    getAssets(state: AssetStateInterface) {
      const assets = state.assetsList.filter(
        (item: AssetInterface) =>
          item.data.investmentDetails.stage === TAssetStage.primary ||
          item.data.investmentDetails.stage === TAssetStage.secondary
        ).sort((a, b) =>
          Number(b.data.investmentDetails.availableBricks) - Number(a.data.investmentDetails.availableBricks)
        ).sort((a, b) =>
          Number(a.forcedRanking) - Number(b.forcedRanking)
        ) as Array<AssetInterface>

      return assets
    },

    getAssetById: (state: AssetStateInterface) => (id: string) => {
      const asset = state.assetsList.find((item: AssetInterface) => item.data.id === id) as AssetInterface

      return asset
    }
  }
}
